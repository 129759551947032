import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import AppAuthContainer from '../containers/AppContainer'
import Header from '../components/header'
import { MuiThemeProvider } from '@material-ui/core/styles';
import Theme from '../utils/theme';
import { StaticQuery, graphql } from "gatsby";
//import './index.css'

//<Header siteTitle={data.site.siteMetadata.title} />

const Layout = ({ children, }) => (
  <StaticQuery
    query={graphql`
      query appDataQuery {
        site {
          siteMetadata {
            title,
            description,
            keywords,
          }
        }
      }
    `}
    render={data => (
      <MuiThemeProvider theme={Theme}>
        <AppAuthContainer>
          {auth => (
            <div>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'description', content: data.site.siteMetadata.description },
                  { name: 'keywords', content: data.site.siteMetadata.keywords },
                ]}
              />
              <Header siteTitle={data.site.siteMetadata.title} auth={auth} />
              <div
                style={{
                  margin: '9rem auto 1rem',
                  maxWidth: '1540px',
                  padding: '1.45rem 1.0875rem 1.45rem',
                  paddingTop: 0,
                }}
                >
                  {children({...auth })}
                </div>
              </div>
            )}
          </AppAuthContainer>
        </MuiThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout;
