import React, { Component } from 'react'
import { Location } from '@reach/router'
import BulletBuilder from '../components/BulletBuilder'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'
import BoxAd from '../components/BoxAd'

const AYCHWON = styled.h1`
  margin: 0;
  font-weight: bolder;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
`

const HeaderTitle = styled.div`
  max-width: 500px;
`

const Header = ({ header, children }) => (
  <HeaderContainer>
    <HeaderTitle>
      <AYCHWON>{header}</AYCHWON>
      {children}
    </HeaderTitle>
  </HeaderContainer>
)

class BulletBuilderContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div>
        <Header header={'Bullet Builder'}>
          <p>
            Search for keywords and compile a bullet-proof bullet. Copy and
            paste to your listing, edit and save for future use.
          </p>
        </Header>
        <Location>
          {({ location }) => (
            <BulletBuilder location={location} {...this.props} />
          )}
        </Location>
      </div>
    )
  }
}

export default BulletBuilderContainer
