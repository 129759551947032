// CardSection.js
import React from 'react';

class BoxAd extends React.Component {

  componentDidMount() {
		(this.adsbygoogle = window.adsbygoogle || []).push({});
	}

  render() {
    return (
        <div style={{border: '1px solid black'}}>
          <ins className="adsbygoogle"
               style={{display:'inline-block', width:'728px', height:'90px'}}
               data-ad-client="ca-pub-9677238417081136"
               data-ad-slot="1609960727"></ins>
        </div>
    );
  }
}

export default BoxAd;

{/* <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- Basic Builder ad -->
<ins class="adsbygoogle"
     style="display:inline-block;width:728px;height:90px"
     data-ad-client="ca-pub-9677238417081136"
     data-ad-slot="1609960727"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script> */}

{/* <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- Basic Builder ad -->
<ins class="adsbygoogle"
     style="display:inline-block;width:728px;height:90px"
     data-ad-client="ca-pub-9677238417081136"
     data-ad-slot="1609960727"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script> */}
