// CardSection.js
import React from 'react';
import {CardElement} from 'react-stripe-elements';
import styled from 'styled-components';

const StyledCardElement = styled(CardElement)`
  background-color: white;
  border: 1px solid grey;
  padding: 1rem;
  margin: 1rem 0;
  align-self: stretch !important;
`;

class CardSection extends React.Component {
  render() {
    console.table(this.props);
    return (
        <StyledCardElement style={{base: {fontSize: '18px'}}} />
    );
  }
}

export default CardSection;
