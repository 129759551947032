import React from 'react'
import { injectStripe } from 'react-stripe-elements'
import styled from 'styled-components'
import Theme from '../../utils/theme'
import { SaveButton } from '../Buttons'
import axios from 'axios'
import { getCurrentUserToken } from '../../fireHelpers/auth'
import CircularProgress from '@material-ui/core/CircularProgress'

const API_URL = process.env.API_URL

const isLoadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}
//import AddressSection from './AddressSection';
import CardSection from './CardSection'

const SubscriptionForm = styled.form`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 2rem;
  background-color: ${Theme.palette.primary.light};
  color: white;
  margin-bottom: 0 !important;
  max-width: 550px;
`

const StripeLoading = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 2rem;
  background-color: ${Theme.palette.primary.light};
  color: white;
  margin-bottom: 0 !important;
  min-width: 550px;
`

const Heading = styled.div`
  line-height: 14px;
  text-align: center;
`

const SubHeader = styled.p`
  margin: 0;
`
const SubscriptionHeader = ({ header, subHeader }) => (
  <Heading>
    <SubHeader>{subHeader}</SubHeader>
    <h1>{header}</h1>
  </Heading>
)

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  async handleSubmit(ev, plan) {
    ev.preventDefault()
    if (this.props.stripe)
      this.props.subscribe({ plan, stripe: this.props.stripe })

    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', name: 'Jenny Rosen'});
  }

  render() {
    return this.props.stripe ? (
      this.props.plan === 'MONTHLY' ? (
        <SubscriptionForm onSubmit={e => this.handleSubmit(e, 'monthly')}>
          <SubscriptionHeader
            header={'For just $5/month'}
            subHeader={'Become a LupaMerch PRO'}
          />
          <CardSection />
          <SaveButton
            size="large"
            color="secondary"
            variant="contained"
            disabled={this.props.isLoading}
            type="submit"
          >
            Confirm Subscription
            {this.props.isLoading && (
              <CircularProgress
                size={24}
                color="secondary"
                style={isLoadingStyle}
              />
            )}
          </SaveButton>
          <SaveButton
            size="small"
            color="secondary"
            type="button"
            onClick={this.props.handleSubscriptionToggle}
          >
            Try an Annual Subscription for %16 off
          </SaveButton>
        </SubscriptionForm>
      ) : (
        <SubscriptionForm onSubmit={e => this.handleSubmit(e, 'annual')}>
          <SubscriptionHeader
            header={'For just $50/year'}
            subHeader={'Become a LupaMerch PRO'}
          />
          <CardSection />
          <SaveButton
            size="large"
            color="secondary"
            variant="contained"
            type="submit"
            disabled={this.props.isLoading}
          >
            Confirm Subscription
            {this.props.isLoading && (
              <CircularProgress
                size={24}
                color="secondary"
                style={isLoadingStyle}
              />
            )}
          </SaveButton>
          <SaveButton
            size="small"
            color="secondary"
            type="button"
            onClick={this.props.handleSubscriptionToggle}
          >
            Prefer a shorter commitment? Try our monthly subscription
          </SaveButton>
        </SubscriptionForm>
      )
    ) : (
      <StripeLoading>Loading Stripe...</StripeLoading>
    )
  }
}

export default injectStripe(CheckoutForm)
