import fb from '../config/constants'
import moment from 'moment'
import Guid from '../utils/Guid'

const firebase = fb.firebase

const metaData = {
  contentType: 'image/jpeg',
}

function generateUploadTask(url, file) {
  return fb.storageRef.child(url).put(file, metaData)
}

export function refFromUrl(url) {
  return fb.storage.refFromURL(url)
}

function saveFileURL(user, url, file) {
  // console.log('SAVING FILE DATA TO FIRESTORE')
  return fb.db
    .collection('users')
    .doc(user.uid)
    .collection('files')
    .add({ fileName: file.name, url, searchEnabled: false })
}

export function uploadImage(user, file, progressor) {
  return new Promise((resolve, reject) => {
    const url = `${user.uid}/files/${file.name}`
    const uploadTask = generateUploadTask(url, file)
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        progressor(progress)
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
        }
      },
      function(error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        let errorMessage
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            errorMessage = 'you are unauthorized to store files here'
            break

          case 'storage/canceled':
            // User canceled the upload
            errorMessage = 'upload canceled'
            break

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            errorMessage = 'unknown error occured while uploaded'
            break
        }
        reject(errorMessage)
      },
      function() {
        // Upload completed successfully, now we can get the download URL

        return uploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            console.log('File available at', downloadURL)
            resolve(saveFileURL(user, downloadURL, file))
          })
          .catch(err => {
            console.log('error:', err)
            reject(err)
          })
      }
    )
  })
}
