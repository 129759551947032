import fb from '../config/constants'
import moment from 'moment'

// saveSentence(this.props.user, sentenceContent, sentenceSelectedWords, JSON.stringify(metaVariables));
// export function saveSentence(user, data, existingId) {
//   if (existingId) {
//     return fb.db
//       .collection('users')
//       .doc(user.uid)
//       .collection('sentences')
//       .doc(existingId)
//       .set({
//         ...data,
//         creationDate: moment.now(),
//       })
//       .then(() => {
//         console.log('Sucessfully Saved!')
//       })
//       .catch(error => console.error('Error writing document:', error))
//   }
//   return fb.db
//     .collection('users')
//     .doc(user.uid)
//     .collection('sentences')
//     .add({
//       ...data,
//       creationDate: moment.now(),
//     })
//     .then(() => {
//       console.log('Sucessfully Saved!')
//     })
//     .catch(error => console.error('Error writing document:', error))
// }

export function updateActiveSearch(userId, fileId, isEnabled) {
  return fb.db
    .collection('users')
    .doc(userId)
    .collection('files')
    .doc(fileId)
    .update({
      searchEnabled: isEnabled,
    })
}

export function updateKeywordSearch(userId, fileId, searchString) {
  return fb.db
    .collection('users')
    .doc(userId)
    .collection('files')
    .doc(fileId)
    .update({
      searchString: searchString,
    })
}
