import React from 'react'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Theme from '../utils/theme'
import { CopyButton, SaveButton, SaveButtons } from '../components/Buttons'
import {
  StepNumber,
  Step,
  StepNumberWrapper,
  KeyWordHeader,
  Tip,
} from '../components/KeywordHeader'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'

const API_URL = process.env.API_URL

const ModalPaper = styled(Paper)`
  margin: auto;
  margin-top: 10%;
  max-width: 550px;
  background-color: ${Theme.palette.primary.light} !important;
  transition: height 2s;
`
const SubscriptionWrapper = styled.div`
  width: 100%;
  height: 200px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: ${Theme.palette.primary.main};
  font-weight: bold;
`

const ErrorMessage = styled.p`
  max-width: 550px;
  padding: 0 2rem;
  color: ${Theme.palette.secondary.dark};
`

export const UAModal = ({
  modalOpen,
  handleModalClose,
  children,
  isError,
  errorMessage,
}) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      onClose={handleModalClose}
    >
      <ModalPaper>
        <div
          style={{
            alignSelf: 'center',
            textAlign: 'center',
            backgroundColor: 'black',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0.5rem 1rem 0.5rem 2rem',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton color="secondary" onClick={handleModalClose}>
            <Close style={{ fontSize: 32 }} />
          </IconButton>
        </div>
        {children}
        {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </ModalPaper>
    </Modal>
  )
}

export const UAUpdateSubModal = ({ isLoading, onClick, text }) => (
  <SubscriptionWrapper>
    <div style={{ color: 'white' }}>{text}</div>
    <SaveButton
      size="large"
      color="secondary"
      variant="contained"
      onClick={onClick}
    >
      CONFIRM
      {isLoading && <CircularProgress size={24} color="secondary" />}
    </SaveButton>
  </SubscriptionWrapper>
)

export const UAFlashErrorMessage = ({ isLoading, onClick, text }) => (
  <SubscriptionWrapper>
    <div style={{ color: 'white' }}>{text}</div>
    <SaveButton
      size="large"
      color="secondary"
      variant="contained"
      onClick={onClick}
    >
      OKAY
      {isLoading && <CircularProgress size={24} color="secondary" />}
    </SaveButton>
  </SubscriptionWrapper>
)
