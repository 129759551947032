import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import Paper from '@material-ui/core/Paper';

export const PlanLinkWrapper = styled(Paper)`
  width: 20rem;
  height: 20rem;
  margin: 1em;
`;

export const PlanLink = styled(Link)`
  text-decoration: none !important;
  color: black;
`;

export const Plans = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const AYCHTOO = styled.div`
  font-size: 50px;
  width: 100%;
  font-kerning: 20px;
  letter-spacing: 7px;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
`;

export const MoneySpan = styled.div`
  font-size: 40px;
  display: inline-block;
`;

export const PeriodSpan = styled.div`
  font-size: 30px;
  margin-bottom: -15px;
  display: inline-block;
`;
