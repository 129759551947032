import React from 'react'
import { Link } from 'gatsby'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import FingerPrint from '@material-ui/icons/FingerPrint'
import Cancel from '@material-ui/icons/Cancel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Image from '@material-ui/icons/Image'
import Paper from '@material-ui/core/Paper'
import MuInput from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styled, { css } from 'styled-components'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import FileSaver from 'file-saver'

import Theme from '../utils/theme'
import Header from '../components/DisplayHeader'
import {
  PlanLinkWrapper,
  PlanLink,
  Plans,
  AYCHTOO,
  MoneySpan,
  PeriodSpan,
} from '../components/PlanMarketing'
import { uploadImage, refFromUrl } from '../fireHelpers/storage'
import { watchFileData } from '../fireHelpers/user'
import { updateActiveSearch } from '../fireHelpers/catsplat'
import HighlightedDesignSearch from '../components/HighlightedDesignSearch'

const AcceptDrop = css`
  border-color: green !important;
  background: red;
`

const FileUploadDropZone = styled(Dropzone)`
  position: relative;
  display: block;
  width: 100%;
  height: 250px;
  border: 1px solid ${Theme.palette.primary.main};
`
const UploadWell = styled.div`
  &&& {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: #f9fafb;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    border-color: ${props =>
      props.rejected ? 'red' : props.staged ? 'green' : null};
    .upload-text {
      font-size: 16px;
      color: ${props =>
        props.rejected ? 'red' : props.staged ? 'green' : null};
    }
    button {
      background: transparent;
      height: 100%;
      width: 100%;
      border: 0;
    }
    &:hover {
      border-color: ${props =>
        props.rejected ? 'red' : props.staged ? 'green' : '#0092e0'};
    }
  }
`

const ActionButton = styled(Button)`
  margin: 0.25rem 1rem !important;
`

const CopyButton = ActionButton.extend`
  margin: 10px !important;
`
const ThumbImage = styled.img`
  margin: 1em;
  height: 100px;
  @media (max-width: 1000px) {
    height: 75px;
    margin: 0.25em;
  }
`
const DisplayLayout = styled.div`
  display: grid;
  column-gap: 1em;
  grid-template-columns: minmax(auto, 50%) minmax(500px, 50%);
  @media (max-width: 1000px) {
    row-gap: 1em;
    grid-template-columns: 1fr;
  }
`

const Design = styled.div`
  border: 4px solid
    ${({ selected }) =>
      selected ? Theme.palette.highlight.main : 'transparent'};
  display: flex;
  margin: 1em 1em 0 0;
  flex-direction: column;
  background-color: ${({ searchEnabled }) =>
    searchEnabled ? Theme.palette.secondary.main : Theme.palette.primary.main};
  &:hover {
    border: 4px solid ${Theme.palette.highlight.hover};
  }
  @media (max-width: 1000px) {
    margin: 1em 0.25em;
  }
`
const Designs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const HighlightedDesignPaper = styled(Paper)`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 1em;
`
const BetaColorBar = styled.div`
  background: darkgrey;
  background: repeating-linear-gradient(
    45deg,
    ${Theme.palette.primary.superLight},
    ${Theme.palette.primary.superLight} 10px,
    ${Theme.palette.primary.main} 10px,
    ${Theme.palette.primary.main} 20px
  );
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  color: white;
`

const DesignNode = ({
  searchEnabled,
  url,
  fileKey,
  handleToggleSearch,
  handleFileSelect,
  selectedFileKey,
}) => (
  <Design
    searchEnabled={searchEnabled}
    selected={fileKey === selectedFileKey}
    onClick={e => handleFileSelect(e, { fileKey })}
  >
    <Button
      color={searchEnabled ? 'primary' : 'secondary'}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        handleToggleSearch({
          fileKey,
          willEnable: !searchEnabled,
        })
      }}
    >
      {searchEnabled ? <Cancel size="huge" /> : <FingerPrint size="huge" />}

      {searchEnabled ? 'DISABLE SEARCH' : 'ACTIVATE SEARCH'}
    </Button>
    <ThumbImage src={url} />
  </Design>
)

const HighlightedDesign = ({ data, selectedFileKey, uid }) => (
  <HighlightedDesignPaper>
    <ThumbImage src={data.url} />
    <div>
      <div>
        <BetaColorBar>this feature is currently in development</BetaColorBar>
        <Button
          variant="outlined"
          onClick={() => {
            return refFromUrl(data.url)
              .getDownloadURL()
              .then(url => {
                // This can be downloaded directly:
                // console.log('DOWNLOAD')
                return axios
                  .get(url, {
                    responseType: 'blob',
                  })
                  .then(response => {
                    // console.log('response:', response)
                    FileSaver.saveAs(response.data, data.fileName)
                  })
                // var xhr = new XMLHttpRequest()
                // xhr.responseType = 'blob'
                // xhr.onload = function(event) {
                //   var blob = xhr.response
                // }
                // xhr.open('GET', url)
                // xhr.send()
              })
              .catch(err => {
                console.log('error.code:', err.code)
              })
          }}
        >
          DOWNLOAD
        </Button>
      </div>
      <MuInput
        disabled
        label="File Name"
        fullWidth
        inputProps={{ readOnly: true }}
        defaultValue={data.fileName}
        value={data.fileName}
      />

      <MuInput
        disabled
        label="Search Enabled"
        fullWidth
        inputProps={{ readOnly: true }}
        defaultValue={data.searchEnabled}
        value={data.searchEnabled}
      />
    </div>
    <HighlightedDesignSearch
      selectedFileKey={selectedFileKey}
      searchString={data.searchString}
      uid={uid}
    />
  </HighlightedDesignPaper>
)

class DataBaseContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      file: {},
      newFileName: '',
      staged: false,
      rejected: false,
      errorMessage: null,
      percentComplete: 0,
      uploading: false,
      fileData: {},
      fileKeys: [],
      selectedFileKey: null,
    }
  }

  componentDidMount() {
    this.unsubscribeFiles = watchFileData(this.props.user, this.updateFileData)
  }

  componentWillUnmount() {
    this.unsubscribeFiles && this.unsubscribeFiles()
  }

  updateFileData = fileData => {
    let keys = Object.keys(fileData)
    this.setState({ fileData, fileKeys: keys })
  }

  progressor = percent => {
    this.setState({ percentComplete: percent })
  }

  handleToggleSearch = ({ fileKey, willEnable }) => {
    updateActiveSearch(this.props.user.uid, fileKey, willEnable)
  }

  handleFileSelect = (e, { fileKey }) => {
    e.stopPropagation()
    // console.log('fileKey:', fileKey)
    this.setState(
      prevState =>
        prevState.selectedFileKey != fileKey
          ? { selectedFileKey: fileKey }
          : { selectedFileKey: null }
    )
  }
  handleFileUpload = () => {
    if (this.state.staged) {
      //   const formData = new FormData()
      //   formData.append('file', this.state.file)
      this.setState({ uploading: true })
      uploadImage(this.props.user, this.state.file, this.progressor)
        .then(response => {
          // console.log('response:', response)
          this.setState({
            file: {},
            newFileName: '',
            staged: false,
            uploading: false,
          })
        })
        .catch(errorMessage => {
          this.setState({
            file: {},
            newFileName: '',
            staged: false,
            uploading: false,
            error: errorMessage,
          })
        })
    }
  }

  handleStagingFile = (acceptedFiles, rejectedFiles) => {
    console.log('staging file now!')
    if (rejectedFiles.length) {
      setTimeout(() => this.setState({ rejected: false }), 2000)
      return this.setState({ rejected: true })
    }
    const file = acceptedFiles[0]
    this.setState({ loading: true })
    return this.setState({
      file,
      newFileName: file.name,
      loading: false,
      staged: true,
    })
  }

  handleCancelUpload = () => {
    this.setState({
      file: {},
      newFileName: '',
      staged: false,
      percentComplete: 0,
    })
  }

  render() {
    const { file, staged, rejected, selectedFileKey } = this.state
    if (this.props.userData.accountType === 'free')
      return (
        <div>
          <Plans>
            <PlanLink to="/app/account?plan=ANNUAL&modal=true">
              <PlanLinkWrapper>
                <AYCHTOO>YEARLY</AYCHTOO>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <MoneySpan>$50/</MoneySpan>
                  <PeriodSpan> year</PeriodSpan>
                </div>
              </PlanLinkWrapper>
            </PlanLink>
            <PlanLink to="/app/account?plan=MONTHLY&modal=true">
              <PlanLinkWrapper>
                <AYCHTOO>MONTHLY</AYCHTOO>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <MoneySpan>$5/</MoneySpan>
                  <PeriodSpan> month</PeriodSpan>
                </div>
              </PlanLinkWrapper>
            </PlanLink>
          </Plans>
        </div>
      )
    return (
      <div>
        <Header header={'CatSplat'}>
          <p>
            {'Upload images you want us to crawl popular sites for copycats'}
          </p>
        </Header>
        <DisplayLayout>
          <div>
            <FileUploadDropZone
              onDrop={this.handleStagingFile}
              accept=".jpg"
              id="files"
              innerRef={ref => {
                this.dropzoneRef = ref
              }}
              acceptClassName={`${AcceptDrop}`}
            >
              <UploadWell
                rejected={this.state.rejected}
                staged={this.state.staged}
              >
                {staged ? (
                  <p className="upload-text">
                    {`${this.state.newFileName} is ready to be uploaded!`}
                  </p>
                ) : (
                  <Button
                    color={rejected ? 'primary' : 'secondary'}
                    onClick={() => {
                      this.dropzoneRef.open()
                    }}
                  >
                    <Image size="huge" name="file excel outline" />
                    {rejected
                      ? 'Whoops - wrong file type! .jpg'
                      : 'Click or drag images to upload'}
                  </Button>
                )}
              </UploadWell>
            </FileUploadDropZone>
            {this.state.uploading && (
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={this.state.percentComplete}
              />
            )}
            {this.state.staged && (
              <div>
                <CopyButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={this.handleFileUpload}
                >
                  SAVE
                </CopyButton>
                <CopyButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={this.handleCancelUpload}
                >
                  CANCEL
                </CopyButton>
              </div>
            )}
            {this.state.errorMessage && <span>{this.state.errorMessage}</span>}
          </div>
          <div>
            {selectedFileKey && (
              <HighlightedDesign
                data={this.state.fileData[selectedFileKey]}
                selectedFileKey={selectedFileKey}
                uid={this.props.user.uid}
              />
            )}
          </div>
        </DisplayLayout>
        <Designs>
          {this.state.fileKeys.length &&
            this.state.fileKeys.map(fileKey => {
              const fileData = this.state.fileData[fileKey]
              return (
                <DesignNode
                  key={fileKey}
                  selectedFileKey={selectedFileKey}
                  fileKey={fileKey}
                  handleFileSelect={this.handleFileSelect}
                  handleToggleSearch={this.handleToggleSearch}
                  {...fileData}
                />
              )
            })}
        </Designs>
      </div>
    )
  }
}

export default DataBaseContainer
