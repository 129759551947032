import React from 'react'
import { Router } from '@reach/router'
import CircularProgress from '@material-ui/core/CircularProgress'
import BulletBuilderContainer from '../../containers/BulletBuilderContainer'
import DataBaseContainer from '../../containers/DataBaseContainer'
import UserAccountContainer from '../../containers/UserAccountContainer'
import CatSplatContainer from '../../containers/CatSplatContainer'
import Login from '../../components/Login'
import PrivateRoute, { PublicRoute } from '../../components/PrivateRoute'
import { firebaseAuth } from '../../config/constants'
import styled, { keyframes } from 'styled-components'
import LOGO from '../../assets/Alpha-Merch-Badge-500-animated.svg'
import LinearProgress from '@material-ui/core/LinearProgress'
import Layout from '../../layouts/app.js'
import { StripeProvider } from 'react-stripe-elements'
import { StaticQuery, graphql } from 'gatsby'

//STRIPE_PK={data.site.siteMetadata.STRIPE_PK}

const STRIPE_PK = process.env.STRIPE_PK
// console.log('STRIPE_PK after CONFIG:', STRIPE_PK)

const frames = keyframes`
0% { fill: blue; }
40% { fill: red; }
60% { fill: yellow; }
100% { fill: red; }
`

const ANIMATEDLOGO = styled.img`
  animation: ${frames} 5s infinite;
  &.path1: {
    animation: ${frames} 5s infinite;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const Loading = () => (
  <LoadingWrapper>
    <ANIMATEDLOGO src={LOGO} />
    <LinearProgress size={200} color="secondary" />
  </LoadingWrapper>
)

class App extends React.Component {
  constructor(props) {
    // console.log('PROPS.STRIPE_PK:', props.STRIPE_PK)
    // console.log('process.env.STRIPE_PK:', process.env.STRIPE_PK)
    super(props)
    this.state = {
      stripe: null,
      STRIPE_PK: props.STRIPE_PK || process.env.STRIPE_PK,
    }
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(this.state.STRIPE_PK) })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(this.state.STRIPE_PK) })
      })
    }
  }
  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Layout>
          {({
            loading,
            authed,
            sentences,
            sentenceIds,
            user,
            location,
            userData,
            updateUserDataName,
          }) => {
            return loading ? (
              <Loading />
            ) : (
              <div>
                <PrivateRoute
                  authed={authed}
                  user={user}
                  sentences={sentences}
                  sentenceIds={sentenceIds}
                  userData={userData}
                  path="/app/bulletbuilder"
                  component={BulletBuilderContainer}
                />
                <PrivateRoute
                  authed={authed}
                  user={user}
                  sentences={sentences}
                  sentenceIds={sentenceIds}
                  userData={userData}
                  path="/app/bullets"
                  component={DataBaseContainer}
                />
                <PrivateRoute
                  authed={authed}
                  user={user}
                  sentences={sentences}
                  sentenceIds={sentenceIds}
                  userData={userData}
                  path="/app/catsplat"
                  component={CatSplatContainer}
                />
                <PrivateRoute
                  authed={authed}
                  user={user}
                  sentences={sentences}
                  userData={userData}
                  path="/app/account"
                  component={UserAccountContainer}
                  updateUserDataName={updateUserDataName}
                />
                <PublicRoute
                  authed={authed}
                  user={user}
                  component={Login}
                  path="/app/login"
                />
              </div>
            )
          }}
        </Layout>
      </StripeProvider>
    )
  }
}

const AppWithData = () => {
  return (
    <StaticQuery
      query={graphql`
        query appPageQuery {
          site {
            siteMetadata {
              STRIPE_PK
            }
          }
        }
      `}
      render={data => <App STRIPE_PK={data.site.siteMetadata.STRIPE_PK} />}
    />
  )
}

export default AppWithData
