import React from 'react'
import {
  UAFlashErrorMessage,
  UAModal,
} from '../../containers/UserAccountModalContainer'

export const FlashErrorModal = props => {
  const { open, errorMessage, handleClose } = props
  return (
    <UAModal modalOpen={open} handleModalClose={handleClose}>
      <UAFlashErrorMessage text={errorMessage} onClick={handleClose} />
    </UAModal>
  )
}
