import React from 'react'
import { Link } from 'gatsby'
import Divider from '@material-ui/core/Divider'
import MUTable from '@material-ui/core/Table'
import MUTableBody from '@material-ui/core/TableBody'
import MUTableCell from '@material-ui/core/TableCell'
import MUTableHead from '@material-ui/core/TableHead'
import MUTableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import ArrowUp from '@material-ui/icons/ExpandLess'
import ArrowDown from '@material-ui/icons/ExpandMore'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { Value } from 'slate'
import Plain from 'slate-plain-serializer'
import Theme from '../utils/theme'
import Header from '../components/DisplayHeader'
import SentenceEditor from '../components/SentenceEditor'
import {
  PlanLinkWrapper,
  PlanLink,
  Plans,
  AYCHTOO,
  MoneySpan,
  PeriodSpan,
} from '../components/PlanMarketing'
import { customInitialValue } from '../utils/staticContent'
import { saveSentence } from '../fireHelpers/user'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

const MUCell = styled(MUTableCell)`
  border: 3px solid grey;
  background-color: white;
  word-wrap: normal;
  max-width: 400px;
  padding: 0.5rem 1.75rem !important;
`

const MUTitleCell = MUCell.extend`
  line-height: 0.8em;
  font-size: 9px !important;
  font-weight: bold !important;
`

const MUHeaderCell = MUTitleCell.extend`
  color: ${Theme.palette.primary.contrastText} !important;
  background-color: ${Theme.palette.primary.main};
  border-color: ${Theme.palette.primary.main};
  text-align: center;
`

const BulletText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BulletInput = styled.textarea`
  width: 100%;
  max-height: 120px;
  min-height: 80px;
  overflow: auto;
  resize: none;
  border: none;
  outline: none;
  color: ${Theme.palette.primary.main};
`

const ActionButton = styled(Button)`
  margin: 0.25rem 1rem !important;
`

const CopyButton = ActionButton.extend`
  margin: 0px !important;
  margin-top: 10px !important;
`

const StyledBulletLink = styled(Link)`
  text-decoration: none;
  color: ${Theme.palette.primary.main};

  &:hover {
    color: ${Theme.palette.secondary.main};
  }

  &:visited {
    color: ${Theme.palette.primary.visited};

    &:hover {
      color: ${Theme.palette.secondary.main};
    }

    &:focus {
      color: ${Theme.palette.secondary.light};
    }
  }

  &:focus {
    color: ${Theme.palette.secondary.light};
  }
`
const Bar = styled.div`
  background-color: ${Theme.palette.primary.main};
  padding: 7px;
  font-weight: bold !important;
  color: white;
`
const EditorPaper = styled(Paper)`
  width: 100%;
`
const CustomContentPaper = styled(Paper)`
  margin-bottom: 19px;
`
const NewNameCell = styled.div`
  max-width: 400px;
`
const Table = styled.div`
  width: 100%;
`
const TableHead = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const TableBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1000px) {
    grid-row-gap: 2em;
  }
`
const TableRow = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 400px) 1fr 1fr;
  grid-column: 1 / -1;
`

const Cell = styled.div`
  border: 3px solid grey;
  background-color: white;
  word-wrap: normal;
  padding: 0.5rem 1.75rem !important;
  @media (max-width: 1000px) {
    padding: 0.5rem !important;
    grid-column: span 3;
  }
`

const TitleCell = Cell.extend`
  ${'' /* line-height: 0.8em;
  font-size: 9px !important; */} font-weight: bold !important;
`

const HeaderCell = TitleCell.extend`
  color: ${Theme.palette.primary.contrastText} !important;
  background-color: ${Theme.palette.primary.main};
  border-color: ${Theme.palette.primary.main};
  text-align: center;
`

class DataBaseContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 'desc',
      bulletValue1: Value.fromJSON(customInitialValue),
      bulletValue2: Value.fromJSON(customInitialValue),
      displayName: '',
      showEditor: false,
    }
    this.onChange = ({ value, valueName = 'bulletValue1' }) => {
      // console.log('onChange:', valueName)
      return this.setState({ [valueName]: value })
    }
  }

  onDisplayNameChange = e => {
    this.setState({ displayName: e.target.value })
  }

  copyInputValue = id => {
    const input = document.getElementById(id)
    input.select() // selecting value
    document.execCommand('copy', false) // Copied to clipboard
  }

  toggleEditorDisplay = () =>
    this.setState({ showEditor: !this.state.showEditor })

  onSaveSentence = ({ isUpdate } = {}) => {
    const sentenceContent1 = JSON.stringify(this.state.bulletValue1.toJSON())
    const sentenceContent2 = JSON.stringify(this.state.bulletValue2.toJSON())
    const plainTextContent1 = Plain.serialize(this.state.bulletValue1)
    const plainTextContent2 = Plain.serialize(this.state.bulletValue2)
    const sentenceKeys1 = []
    const sentenceKeys2 = []
    const selectedWords = {}
    const keys = []
    const types = []
    const synonymKeysAvail = []
    const nextsynonymKey = 1
    const relatedWordKeysAvail = []
    const nextrelatedWordKey = 1
    const sentenceTemplateName1 = 'CUSTOM'
    const sentenceTemplateName2 = 'CUSTOM'
    const displayName = this.state.displayName
    const sentenceId = null

    const data = {
      sentenceContent1,
      sentenceContent2,
      plainTextContent1,
      plainTextContent2,
      sentenceKeys1,
      sentenceKeys2,
      selectedWords,
      nextsynonymKey,
      nextrelatedWordKey,
      synonymKeysAvail,
      relatedWordKeysAvail,
      keys,
      types,
      sentenceTemplateName1,
      sentenceTemplateName2,
      displayName,
    }
    saveSentence(this.props.user, data, sentenceId)
    this.setState({
      displayName: '',
      bulletValue1: Value.fromJSON(customInitialValue),
      bulletValue2: Value.fromJSON(customInitialValue),
    })
  }

  render() {
    const { bulletValue1, bulletValue2, displayName } = this.state
    if (this.props.userData.accountType === 'free')
      return (
        <div>
          <Plans>
            <PlanLink to="/app/account?plan=ANNUAL&modal=true">
              <PlanLinkWrapper>
                <AYCHTOO>YEARLY</AYCHTOO>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <MoneySpan>$50/</MoneySpan>
                  <PeriodSpan> year</PeriodSpan>
                </div>
              </PlanLinkWrapper>
            </PlanLink>
            <PlanLink to="/app/account?plan=MONTHLY&modal=true">
              <PlanLinkWrapper>
                <AYCHTOO>MONTHLY</AYCHTOO>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <MoneySpan>$5/</MoneySpan>
                  <PeriodSpan> month</PeriodSpan>
                </div>
              </PlanLinkWrapper>
            </PlanLink>
          </Plans>
        </div>
      )
    return (
      <div>
        <Header header={'Saved Bullets'}>
          <p>{'A one-stop tool for managing saved bullets'}</p>
        </Header>
        <CustomContentPaper>
          <Bar>
            {'ADD YOUR OWN CONTENT'}{' '}
            <IconButton
              color="secondary"
              size="small"
              onClick={this.toggleEditorDisplay}
            >
              {this.state.showEditor ? <ArrowUp /> : <ArrowDown />}
            </IconButton>
          </Bar>
          {this.state.showEditor && (
            <div>
              <Table>
                <TableBody>
                  <TableRow key={'newBullet'}>
                    <TitleCell>
                      <NewNameCell>
                        <TextField
                          onSubmit={e => {
                            e.preventDefault()
                            this.onSaveSentence()
                          }}
                          autoFocus
                          fullWidth
                          helperText={'Name your custom bullet set'}
                          value={displayName}
                          onChange={this.onDisplayNameChange}
                        />
                        <CopyButton
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={() => this.onSaveSentence()}
                        >
                          SAVE
                        </CopyButton>
                      </NewNameCell>
                    </TitleCell>
                    <Cell>
                      <EditorPaper>
                        <SentenceEditor
                          onChange={({ value }) =>
                            this.onChange({ value, valueName: 'bulletValue1' })
                          }
                          value={bulletValue1}
                        />
                      </EditorPaper>
                    </Cell>
                    <Cell>
                      <EditorPaper>
                        <SentenceEditor
                          onChange={({ value }) =>
                            this.onChange({ value, valueName: 'bulletValue2' })
                          }
                          value={bulletValue2}
                        />
                      </EditorPaper>
                    </Cell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
        </CustomContentPaper>
        <Table>
          {isWidthUp('md', this.props.width) && (
            <TableHead>
              <TableRow>
                <HeaderCell>{'NAME'}</HeaderCell>
                <HeaderCell>{'Bullet 1'}</HeaderCell>
                <HeaderCell>{'Bullet 2'}</HeaderCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {this.props.sentenceIds
              ? this.props.sentenceIds.map(id => {
                  const val1 = Value.fromJSON(
                    this.props.sentences[id].bulletValue1
                  )
                  const plain1 = Plain.serialize(val1)
                  const val2 = Value.fromJSON(
                    this.props.sentences[id].bulletValue2
                  )
                  const plain2 = Plain.serialize(val2)
                  return (
                    <TableRow key={id}>
                      <TitleCell>
                        <StyledBulletLink
                          to={`/app/bulletbuilder?bulletId=${id}`}
                        >
                          {this.props.sentences[id].displayName}
                        </StyledBulletLink>
                      </TitleCell>
                      <Cell>
                        <BulletText>
                          <BulletInput id={`${id}bullet1`} value={plain1} />
                          <CopyButton
                            size="small"
                            color="secondary"
                            variant="outlined"
                            onClick={() => this.copyInputValue(`${id}bullet1`)}
                          >
                            Copy
                          </CopyButton>
                        </BulletText>
                      </Cell>
                      <Cell>
                        <BulletText>
                          <BulletInput id={`${id}bullet2`} value={plain2} />
                          <CopyButton
                            size="small"
                            color="secondary"
                            variant="outlined"
                            onClick={() => this.copyInputValue(`${id}bullet2`)}
                          >
                            Copy
                          </CopyButton>
                        </BulletText>
                      </Cell>
                    </TableRow>
                  )
                })
              : null}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withWidth()(DataBaseContainer)
