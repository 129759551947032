import React from 'react'
import { Link } from 'gatsby'
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import queryString from 'query-string'
import Theme from '../utils/theme'
import { watchUser } from '../fireHelpers/user'
import Moment from 'moment'
import { CopyButton, SaveButton, SaveButtons } from '../components/Buttons'
import { Elements } from 'react-stripe-elements'
import SubscriptionForm from '../components/SubscriptionForm'
import {
  StepNumber,
  Step,
  StepNumberWrapper,
  KeyWordHeader,
  Tip,
} from '../components/KeywordHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import { getCurrentUserToken } from '../fireHelpers/auth'
import {
  updateUserName,
  updateSubscriptionType,
  renewSubscriptionBeforeEndDate,
} from '../fireHelpers/user'
import { UAUpdateSubModal, UAModal } from './UserAccountModalContainer'
import { FlashErrorModal } from '../components/ErrorModal'

const API_URL = process.env.API_URL

const freeBullets = [
  'Dashboard access to Trending and Holiday Countdown',
  '30 bullet builder searches daily',
]
const monthlyBullets = [
  'Dashboard access to Trending and Holiday Countdown',
  'UNLIMITED bullet builder searches',
  'Saved Bullets database management - save your favorite bullet sets for future use',
  'New tools as they are completed - like our in-the-works CatSplat copycat image tracking tool',
]
const annualBullets = [
  'Dashboard access to Trending and Holiday Countdown',
  'UNLIMITED bullet builder searches',
  'Saved Bullets database management - save your favorite bullet sets for future use',
  'New tools as they are completed - like our in-the-works CatSplat copycat image tracking tool',
  'Save 16%',
]

const AYCHWON = styled.h1`
  margin: 0;
  font-weight: bolder;
`
const Header = ({ header, children }) => (
  <div>
    <AYCHWON>{header}</AYCHWON>
    {children}
  </div>
)

const ProfileForm = styled.form`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2rem;
`

const ProfileSave = SaveButton.extend`
  align-self: flex-end;
`

const ProfileTextField = styled(TextField)`
  margin: 1em !important;
`

const InfoTitle = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`

const AccountInfo = styled.div`
  width: 20rem;
  border: 2px solid grey;
  padding: 1rem;
`
const InfoDetail = styled.span`
  font-weight: normal;
`

const SCHeader = styled.div`
  padding-left: 0.5em;
  color: white;
  font-weight: bold;
  height: 2em;
  line-height: 2.5em;
  font-size: 2em;
  background-color: ${Theme.palette.secondary.main};
`

const SCard = styled.div`
  width: 300px;
  margin: 1em 3em 0 0;
`

const SCardContent = styled.div`
  height: 300px;
  color: white;
  padding: 0.5em 0.5em 0.5em 0;
  font-weight: 500;
  background-color: ${props => Theme.palette.primary[props.color]};
`

const SCardAction = styled.div`
  height: 100px;
  padding: 0 2rem;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: ${props => Theme.palette.primary[props.color]};
`

const DullButton = SaveButton.extend`
  border-color: white !important;
  color: white !important;
`
const SubscriptionCard = ({
  heading,
  color,
  price,
  bullets,
  handleModalOpen,
}) => (
  <SCard>
    <SCHeader>{heading}</SCHeader>
    <SCardContent color={color}>
      <ul style={{ listStyleType: 'none' }}>
        {bullets.map((bullet, idx) => (
          <li key={`${idx}`}>{bullet}</li>
        ))}
      </ul>
    </SCardContent>
    <SCardAction color={color}>
      {price ? (
        <KeyWordHeader noMargin>
          {' '}
          <StepNumber size={5} num={`$${price}`} />
        </KeyWordHeader>
      ) : null}
      {price ? (
        <DullButton
          size="large"
          color="secondary"
          variant="outlined"
          onClick={handleModalOpen}
        >
          UPGRADE
        </DullButton>
      ) : null}
    </SCardAction>
  </SCard>
)

const InfoField = ({ label, data }) => (
  <InfoTitle>
    {label}
    <InfoDetail>{data}</InfoDetail>
  </InfoTitle>
)

const SUBSCRIPTION = 'SUBSCRIPTION'
const PROFILE = 'PROFILE'
const HELP = 'HELP'

const ANNUAL = 'ANNUAL'
const MONTHLY = 'MONTHLY'

class UserAccountContainer extends React.Component {
  constructor(props) {
    super(props)
    const parsed = queryString.parse(props.location.search)
    this.state = {
      order: 'desc',
      modalOpen: parsed.modal ? true : false,
      accountView: parsed.plan ? SUBSCRIPTION : PROFILE,
      plan: parsed.plan === MONTHLY ? MONTHLY : ANNUAL,
      errorMessage: '',
      flashModalOpen: false,
      isError: false,
      isLoading: false,
      userData: {
        displayName: '',
        email: '',
        subSince: null,
        accountType: null,
        creationDate: null,
        subscriptionType: null,
        nextChargeDateTime: null,
        subscriptionEnds: null,
        subscribed: false,
      },
    }
    this.nameRef = React.createRef()
  }

  copyInputValue = id => {
    const input = document.getElementById(id)
    input.select() // selecting value
    document.execCommand('copy', false) // Copied to clipboard
  }

  handleSubRedirect = e => {
    e.preventDefault
    if (this.props.userData.accountType === 'free') {
      this.setState({ redirectToSubscription: true })
    }
  }

  handleSubscriptionToggle = e => {
    e.preventDefault
    this.setState(
      prevState =>
        prevState.plan === ANNUAL ? { plan: MONTHLY } : { plan: ANNUAL }
    )
  }

  handleSubscribe = async ({ plan, stripe }) => {
    this.beginLoadingState()
    let token
    let idToken
    try {
      token = await stripe.createToken({ name: this.props.user.email })
      if (token.error) throw token.error
      idToken = await getCurrentUserToken()
    } catch (err) {
      return this.errorState(err.message)
    }

    axios
      .post(`${API_URL}/subscribe/${plan}`, {
        token,
        idToken,
        email: this.props.user.email,
      })
      .then(response => {
        if (response.status === 200) {
          this.subscriptionSuccess()
          console.log('PURCHASE COMPLETE')
        }
      })
      .catch(err => {
        this.errorState(err.response.data)
      })
  }

  handleUpdateSubscription = ({ subscriptionType, renew }) => {
    this.beginLoadingState()
    if (renew) {
      renewSubscriptionBeforeEndDate(this.props.user.uid)
        .then(result => {
          this.setState({ modalOpen: false, isLoading: false })
        })
        .catch(err => {
          this.errorState(
            'Sorry! We were unable to update your subscription at this time. If this persists please contact us!'
          )
          this.setState({ modalOpen: false, isLoading: false })
        })
    } else {
      updateSubscriptionType(this.props.user.uid, subscriptionType)
        .then(result => {
          this.setState({ modalOpen: false, isLoading: false })
        })
        .catch(err => {
          this.errorState(
            'Sorry! We were unable to update your subscription at this time. If this persists please contact us!'
          )
          this.setState({ modalOpen: false, isLoading: false })
        })
    }
  }

  errorState = message => {
    this.beginLoadingState(false)
    this.setState({ errorMessage: message, isError: true, modalOpen: true })
    setTimeout(() => this.clearError(), 5000)
  }

  flashErrorState = message => {
    this.beginLoadingState(false)
    this.setState({
      errorMessage: message,
      isError: true,
      flashModalOpen: true,
    })
    // setTimeout(() => this.clearFlashModal(), 5000)
  }

  clearError = () => {
    this.setState({ errorMessage: '', isError: false })
  }

  handleCancelSubscription = async () => {
    this.beginLoadingState()
    let idToken = await getCurrentUserToken()
    axios
      .post(`${API_URL}/subscribe/cancel`, { idToken })
      .then(response => this.beginLoadingState(false))
      .catch(err =>
        this.flashErrorState(
          `failed to cancel subscription! contact us for help at lupamerch@gmail.com`
        )
      )
  }

  handleModalClose = () => this.setState({ modalOpen: false })

  handleModalOpen = (plan, type) =>
    this.setState({ modalOpen: true, plan, type })

  beginLoadingState = newState =>
    this.setState({
      isLoading: typeof newState === 'boolean' ? newState : true,
    })
  subscriptionSuccess = () =>
    this.setState({ modalOpen: false, isLoading: false })
  commitUserNameChange = () => {
    updateUserName(this.props.user.uid, this.props.userData.displayName)
  }

  renderProfile = () => {
    return (
      <ProfileForm>
        {this.props.userData.displayName && (
          <ProfileTextField
            defaultValue={this.props.userData.displayName}
            onChange={e => this.props.updateUserDataName(e.target.value)}
            label="Name"
          />
        )}
        <ProfileTextField
          defaultValue={this.props.userData.email}
          value={this.props.userData.email}
          label="E-mail"
        />
        <ProfileSave
          size="large"
          color="secondary"
          variant="contained"
          onClick={this.commitUserNameChange}
        >
          Save
        </ProfileSave>
      </ProfileForm>
    )
  }

  renderSubscription = () => {
    if (this.props.userData.accountType === 'PRO') {
      return (
        <div>
          <Paper
            style={{
              padding: '0.25rem',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              margin: '1rem 0',
            }}
          >
            <ProfileForm>
              <ProfileTextField
                disabled
                value={this.props.userData.subscriptionType}
                label="Subscription Type"
              />
              <ProfileTextField
                disabled
                value={Moment(this.props.userData.nextChargeDateTime).format(
                  'MM.DD.YYYY'
                )}
                label="Next Billing Date"
              />
              {this.props.userData.subscriptionType !== 'TRIAL' &&
                (this.props.userData.nextChargeDateTime ? (
                  <ProfileSave
                    size="large"
                    color="secondary"
                    variant="outlined"
                    onClick={() =>
                      this.handleModalOpen(
                        this.props.userData.subscriptionType === MONTHLY
                          ? ANNUAL
                          : MONTHLY,
                        'SUB'
                      )
                    }
                  >
                    CHANGE SUBSCRIPTION
                  </ProfileSave>
                ) : (
                  <ProfileSave
                    size="large"
                    color="secondary"
                    variant="outlined"
                    onClick={() =>
                      this.handleModalOpen(
                        this.props.userData.subscriptionType === MONTHLY
                          ? ANNUAL
                          : MONTHLY,
                        'RESUB'
                      )
                    }
                  >
                    RE-SUBSCRIBE
                  </ProfileSave>
                ))}
              {this.props.userData.subscribed ? (
                <ProfileSave
                  size="large"
                  color="secondary"
                  variant="outlined"
                  onClick={this.handleCancelSubscription}
                >
                  CANCEL SUBSCRIPTION
                  {this.state.isLoading && (
                    <CircularProgress size={24} color="secondary" />
                  )}
                </ProfileSave>
              ) : null}
            </ProfileForm>
          </Paper>
          {this.props.userData.subscriptionType === 'TRIAL' && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'left',
                flexDirection: 'row',
              }}
            >
              <SubscriptionCard
                heading={'PRO MONTHLY'}
                color={'light'}
                price={5}
                bullets={monthlyBullets}
                handleModalOpen={() => this.handleModalOpen(MONTHLY, 'SUB')}
              />
              <SubscriptionCard
                heading={'PRO ANNUAL'}
                color="light"
                price={50}
                bullets={annualBullets}
                handleModalOpen={() => this.handleModalOpen(ANNUAL, 'SUB')}
              />
            </div>
          )}
        </div>
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          flexDirection: 'row',
        }}
      >
        <SubscriptionCard
          heading={'FREE'}
          color={'superLight'}
          bullets={freeBullets}
        />
        <SubscriptionCard
          heading={'PRO MONTHLY'}
          color={'light'}
          price={5}
          bullets={monthlyBullets}
          handleModalOpen={() => this.handleModalOpen(MONTHLY, 'SUB')}
        />
        <SubscriptionCard
          heading={'PRO ANNUAL'}
          color="light"
          price={50}
          bullets={annualBullets}
          handleModalOpen={() => this.handleModalOpen(ANNUAL, 'SUB')}
        />
      </div>
    )
  }

  render() {
    const { modalOpen, errorMessage, flashModalOpen } = this.state
    const { userData } = this.props
    const subTypeToUpdateTo =
      userData.subscriptionType === MONTHLY ? ANNUAL : MONTHLY
    return (
      <div>
        <FlashErrorModal
          open={flashModalOpen}
          handleClose={() =>
            this.setState({
              flashModalOpen: false,
              errorMessage: '',
              isError: false,
            })
          }
          errorMessage={errorMessage}
        />
        <UAModal
          modalOpen={modalOpen}
          handleModalClose={this.handleModalClose}
          isError={this.state.isError}
          errorMessage={errorMessage}
        >
          {userData.accountType !== 'free' &&
          userData.subscriptionType !== 'TRIAL' ? (
            userData.nextChargeDateTime ? (
              <UAUpdateSubModal
                onClick={() =>
                  this.handleUpdateSubscription({
                    subscriptionType: subTypeToUpdateTo,
                  })
                }
                isLoading={this.state.isLoading}
                text={`UPDATE MY SUBSCRIPTION TO ${subTypeToUpdateTo} AND BILL ME WHEN MY CURRENT SUBSCRIPTION ENDS.`}
              />
            ) : (
              <UAUpdateSubModal
                onClick={() =>
                  this.handleUpdateSubscription({
                    renew: true,
                  })
                }
                isLoading={this.state.isLoading}
                text={`UPDATE MY ${
                  userData.subscriptionType
                } SUBSCRIPTION TO RENEW AND CONTINUE TO BILL ME.`}
              />
            )
          ) : (
            <Elements>
              <SubscriptionForm
                user={this.props.user}
                subscribe={this.handleSubscribe}
                isLoading={this.state.isLoading}
                plan={this.state.plan}
                handleSubscriptionToggle={this.handleSubscriptionToggle}
                subscriptionSuccess={this.subscriptionSuccess}
              />
            </Elements>
          )}
        </UAModal>
        <Header header={'My Account'}>
          <p>{'Manage your account details.'}</p>
          <div
            style={{
              padding: '3rem',
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Paper
              style={{
                padding: '.25rem',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                margin: '1rem 0',
              }}
            >
              <AccountInfo>
                <InfoField label="Account Type:" data={userData.accountType} />
                <InfoField
                  label="Subscribed:"
                  data={
                    userData.subSince
                      ? Moment(userData.subSince.toDate()).fromNow()
                      : 'NOT YET!'
                  }
                />
                <InfoField
                  label="Account Created:"
                  data={Moment(userData.creationDate).fromNow()}
                />
                {userData.nextChargeDateTime ? (
                  <InfoField
                    label="Next Charge Date:"
                    data={Moment(userData.nextChargeDateTime).format(
                      'MM-DD-YYYY'
                    )}
                  />
                ) : null}
                {!userData.subscribed ? (
                  <InfoField
                    label="Subscription Ends:"
                    data={Moment(userData.subscriptionEnds).format(
                      'MM-DD-YYYY'
                    )}
                  />
                ) : null}
              </AccountInfo>
              {this.renderProfile()}
            </Paper>
            {this.renderSubscription()}
          </div>
        </Header>
      </div>
    )
  }
}

export default UserAccountContainer
