import React, { PureComponent } from 'react'
import Paper from '@material-ui/core/Paper'
import MuInput from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styled, { css } from 'styled-components'

import Theme from '../utils/theme'
import { updateKeywordSearch } from '../fireHelpers/catsplat'

const ActionButton = styled(Button)`
  margin: 0.25rem 1rem !important;
`

const CopyButton = ActionButton.extend`
  margin: 10px !important;
`
const HighlightedDesignSearchWrapper = styled.div`
  height: 100px;
  grid-column: span 2;
`
const SingleLineForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
`

export default class HighlightedDesignSearch extends PureComponent {
  state = { searchString: '' }

  componentDidMount() {
    this.hydrateKeywordSearch()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedFileKey != this.props.selectedFileKey) {
      this.hydrateKeywordSearch()
    }
  }

  hydrateKeywordSearch = () =>
    this.setState({ searchString: this.props.searchString || '' })

  updateKeywordSearch = e => {
    e.preventDefault()
    updateKeywordSearch(
      this.props.uid,
      this.props.selectedFileKey,
      this.state.searchString
    )
  }
  updateLocalString = e => this.setState({ searchString: e.target.value })
  render() {
    return (
      <HighlightedDesignSearchWrapper>
        <SingleLineForm onSubmit={this.updateKeywordSearch}>
          <MuInput
            required
            fullWidth
            label="Search String likely to find copycats"
            onChange={this.updateLocalString}
            value={this.state.searchString}
          />
          <CopyButton
            color="secondary"
            variant="outlined"
            onClick={this.updateKeywordSearch}
          >
            UPDATE KEYWORD SEARCH
          </CopyButton>
        </SingleLineForm>
      </HighlightedDesignSearchWrapper>
    )
  }
}
